import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import axios from 'axios';


export class ControlledInput extends Component {
  constructor(props) {
    super(props);
    this.state = { input: props.input, getProjectnames: '', getCitynames: '' };
    this._onChange = this._onChange.bind(this);
    this._onChangeComplete = debounce(this._onChangeComplete.bind(this), 500);
  }

  componentDidMount() {
    var param=window.location.href.split("/");

     if(param[3]==''){
        localStorage.setItem('project_id', '3')
     }

    //get city
    axios.get(global.apiUrl + 'Project/getallcity')
      .then(res => {
        const getCitynames = res.data.city_name;
        this.setState({ getCitynames });
      });
    //get project
    axios.get(global.apiUrl + 'Project/getallProjects')
      .then(res => {
        const getProjectnames = res.data.project_name;
        this.setState({ getProjectnames });

      });
      if(this.props.Banner_id)
      localStorage.setItem('project_id', this.props.Banner_id)
  }
  _onChange(e) {
    if (e.target.id == 'city') {
      const city_id = e.target.value
      localStorage.setItem('city_id', city_id)
    }
    const input = e.target.value
    //alert(input)
    this.setState(() => ({ input }));
    this._onChangeComplete();
  }

  _onChangeComplete() {
    this.props.onChangeComplete(this.props.options.id, this.state.input);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.valueProject !== prevState.valueProject) {
  //     var eProject = document.getElementById("project");
  //     if(eProject){
  //       this.setState({valueProject: eProject.options[eProject.selectedIndex].value})
  //     }
  //   }
  // }

  render() {
    
    var eCity = document.getElementById("city");
    if (eCity) {
      var valueCity = eCity.options[eCity.selectedIndex].value;
    }
    // var eProject = document.getElementById("project");
    // if(eProject){
    //   var valueProject = eProject.options[eProject.selectedIndex].value;
    //   // this.setState({valueProject: eProject.options[eProject.selectedIndex].value})
    //   console.log('value', valueProject)
    // }
    // console.log(this.state.valueProject);

    const opt = this.props.options;
    const cn = `text__input ${!this.props.valid ? 'invalid__value' : ''}`;
    // console.log(this.props)
    // var project_banner_id=this.props.match.params.id;


    if (opt.type === 'textarea') return (
      <>
        <textarea
          //className={cn}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input}
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        />

      </>
    );

    if (opt.type === 'select' && opt.id === 'type') return (
      <>
        <div className="dropdown-select"></div>
        <select
          //className={cn}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input}
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        >
          <option value="0">Select a Type</option>
          <option value="Schedule A Visit">Schedule A Visit</option>
          <option value="Get A Call Back">Get A Call Back</option>
        </select>
      </>
    );

    if (opt.type === 'select' && opt.id === 'project_type') return (
      <>
        <div className="dropdown-select"></div>

        <select
          // className={cn}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input}
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
          // value={localStorage.getItem('project_id')}
        >
          <option value="0">Select a Project</option>
          {(this.state.getProjectnames.length) ? this.state.getProjectnames.map(projectName => {
            if (projectName != null) {
              if (localStorage.getItem('project_id') === projectName.project_id)
                session_project_id = true;
              else
                session_project_id = false;
              return (
                <option key={projectName.project_id} selected={session_project_id} value={projectName.project_id}>{projectName.project_name}</option>
              )
            }
            else
              return (
                <option value="0">No Projects.</option>
              )
          })
            : ''}
        </select>
      </>
    );
    var session_city_id = '';
    var session_project_id = '';
    if (opt.type === 'select' && opt.id === 'city') return (

      <>

        <select
          // className={cn}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input}
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        // value={localStorage.getItem('city_id')}
        >
          <option value="0">Select City</option>
          {(this.state.getCitynames.length) ? this.state.getCitynames.map(cityName => {
            if (cityName != null) {

              if(localStorage.getItem('city_id') == null){
                localStorage.setItem('city_id', cityName.city_id)
              }

              if (localStorage.getItem('city_id') === cityName.city_id)
                session_city_id = true;
              else
                session_city_id = false;
              return (
                <option key={cityName.city_id} selected={session_city_id} value={cityName.city_id}>{cityName.city_name}</option>
              )
            }
            else
              return (
                <option value="0">No City.</option>
              )
          })
            : ''}
        </select>
      </>
    );
    if (opt.type === 'select' && opt.id === 'project') return (
      <>

        <select
          // className={cn}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input}
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        // value={localStorage.getItem('project_id')}
        >
          <option value="0">Select Project</option>
          {(this.state.getProjectnames.length) ? this.state.getProjectnames.filter(x => x.project_city_id == valueCity).map(projectName => {
            if (projectName != null) {
              if (localStorage.getItem('project_id') === projectName.project_id)
                session_project_id = true;
              else
                session_project_id = false;
              return (
                <option key={projectName.project_id} selected={session_project_id} value={projectName.project_id}>{projectName.project_name}</option>
              )
            }
            else
              return (
                <option value="0">No Projects.</option>
              )
          })
            : ''}
        </select>
      </>
    );


    return (
      <>
        <input
          //className={cn}
          type={opt.type}
          id={opt.id}
          name={opt.name}
          placeholder={opt.placeholder}
          required={opt.options.required ? true : false}
          //value={this.state.input} 
          onChange={this._onChange}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        />

      </>
    );
  }
}
