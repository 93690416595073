import React, { Component, Fragment } from 'react'
import OffCanvas from 'react-aria-offcanvas'
import { Link } from "react-router-dom"; 
import { useLocation } from "react-router-dom";

const Navigation = () => {
const location = useLocation();
const { pathname } = location;
const splitLocation = pathname.split("/");
return(
  <nav id="menu">
    <ul>
      <li>
      <Link to={'/about-us'} key={'AboutUs'} id={'AboutUs'} className={splitLocation[1] === 'AboutUs' ? "active" : ""}>{'About Us'}</Link>
      </li>
      <li>
      <Link to={'/news'} key={'news'} id={'news'} className={splitLocation[1] === 'news' ? "active" : ""}>{'News'}</Link>
      </li>
      <li>
      <Link to={'/policy'} key={'policy'} id={'policy'} className={splitLocation[1] === 'policy' ? "active" : ""}>{'Policy'}</Link>
      </li>
     
    </ul>
  </nav>
)
}
 
export default class Canvasmenu extends Component {
  state = {
    isOpen: false,
  }
 
  componentWillMount() {
   // window.addEventListener('scroll', this.close, true);
  }


  open = () => {
    this.setState({ isOpen: true})
    var canvasDiv=document.getElementById("offcanvas-root");
    canvasDiv.classList.add('active');
    setTimeout(function(){ 
      //console.log(document.getElementById("body")[0])
    // document.getElementById("body")[0].style.overflowY = "scroll";
  }, 50);
  }
 
  close = () => {
    this.setState({ isOpen: false })
    var canvasDiv=document.getElementById("offcanvas-root");
    canvasDiv.classList.remove('active');
  }
 
  render() {
    return (
      <Fragment>
        {/* <button
          id="menu-button"
          aria-label="Menu"
          aria-controls="menu"
          aria-expanded={this.state.isOpen}
          onClick={this.open}
        >
          Click here
        </button> */}

        <button 
           id="menu-button"
           aria-label="Menu"
           aria-controls="menu"
           aria-expanded={this.state.isOpen}
           onClick={this.open}
        className="navbar-toggler ml-auto custom-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar4">
                               <span className="navbar-toggler-icon canvas-menu-background"></span>
                                </button>



        <OffCanvas
          isOpen={this.state.isOpen}
          onClose={this.close}
          labelledby="menu-button"
          position="right"
        >
          <span className="canvas-close" onClick={this.close}>&#x2715;</span>
          <Navigation />
        </OffCanvas>
      </Fragment>
    )
  }
}