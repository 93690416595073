import React from 'react';
import ReactDOM from 'react-dom';
// import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);


// const StrictApp = () => (
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// const rootElement = document.getElementById('root');

// // hydrate is required by react-snap.
// if (rootElement.hasChildNodes()) {
//   hydrate(<StrictApp />, rootElement);
// } else {
//   render(<StrictApp />, rootElement);
// }


reportWebVitals();


