import React, { Component } from 'react';
import { ControlledInput } from '../input/input';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactPixel from 'react-facebook-pixel';
import { withRouter } from "react-router-dom";
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};



const validate = (value, options) => {

  if (options.required) {
    if (!value || value === '') return false;
  }

  if (options.length) {

    if (value.length > 120) return false;
  }
  if (options.email) {
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value)) return false;
  }
  if (options.phone) {
    // if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)) return false;
    if (value.match(!/^[0-9-+()]*$/)) return false;
  }
  if (options.url) {
    if (!/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)) return false;
  }
  return true;
};


export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      alert: null,
      icon: 'success',
      message: 'Submission Successful!'
    };

    this._onChangeComplete = this._onChangeComplete.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._resetValidation = this._resetValidation.bind(this);
    this._submit = this._submit.bind(this);
    props.fields.map(n => {
      this.state[n.id] = { value: '', valid: true, options: n.options };
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
    if (document.getElementsByClassName('close')[0])
      document.getElementsByClassName('close')[0].click();
    
  }
  _onChangeComplete(id, value) {

    this.setState(state => { state[id].value = value; return state });
  }

  _onBlur(e) {

    const { id, value } = e.target;
    const valid = validate(value, this.state[id].options);
    this.setState(state => { state[id].valid = valid; return state });
  }

  _resetValidation(e) {
    const { id } = e.target;
    if (id)
      this.setState(state => { state[id].valid = true; return state });
  }


  _submit(e) {
    e.preventDefault();
    var customValid = false;
    var customValidDownload = false;
    var customValidBlog = false;
    console.log(this.props)
    if (this.props.submitText === 'Enquire Now') {

      if (this.state.fullname.value == '') {
        document.getElementById('fullname').classList.add('invalid__value');
        customValid = false;
      }
      else {
        document.getElementById('fullname').classList.remove('invalid__value');
        customValid = true;
      }
      if (this.state.phone.value == '') {
        document.getElementById('phone').classList.add('invalid__value');
        customValid = false;
      }
      else {
        if (this.state.phone.value != '') {
          var numbers = /^[0-9-+()]*$/;
          if (this.state.phone.value.match(numbers)) {
            customValid = true;
            document.getElementById('phone').classList.remove('invalid__value');
          }
          else {
            customValid = false;
            document.getElementById('phone').classList.add('invalid__value');
          }
        }
      }

      if (this.state.email.value != '') {
        if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email.value)) {
          customValid = true;
          document.getElementById('email').classList.remove('invalid__value');
        }
        else {
          customValid = false;
          document.getElementById('email').classList.add('invalid__value');
        }
      }
      else if (this.state.email.value === '') {

        customValid = false;
        document.getElementById('email').classList.add('invalid__value');
      }


    }


    if (this.props.submitText === 'Search') {
      var searchProject = this.state.project.value;
      localStorage.setItem('project_id', this.state.project.value)

      axios.get(global.apiUrl + 'Project/getProjectbanners?project_id=' + searchProject)
        .then(res => {
          console.log(res.data.banner.project_cat_id);
          //return false;
          if (res.data.banner.project_cat_id == 2) {
            window.location.href = global.projectUrl + 'commercial/' + searchProject;
          }
          else if (res.data.banner.project_cat_id == 1) {
            window.location.href = global.projectUrl + searchProject;
          }
        });
    }


    if (this.props.submitText === 'Enquire Now' && customValid == true) {
      this.setState({ icon: 'info' });
      this.setState({ message: 'Message is sending...' });
      this.setState({ alert: true });
      var contactnameValid = this.state.fullname.valid;
      var contactphoneValid = this.state.phone.valid;

      if (contactnameValid && contactphoneValid) {
        let formData = new FormData();
        var url = window.location.href;
        const typeofpage = url.substring(url.lastIndexOf('/') + 1)
        formData.append('typeofpage', typeofpage);
        formData.append('contact_form_name', this.state.fullname.value);
        formData.append('contact_form_phone', this.state.phone.value);
        formData.append('contact_form_email', this.state.email.value);
        formData.append('contact_form_message', this.state.content.value);
        formData.append('contact_form_type', this.state.type.value);
        if(this.state.project_type.value){
          formData.append('contact_form_project', this.state.project_type.value);
        }else{
          formData.append('contact_form_project', localStorage.getItem('project_id'));
        }
        customValid = false;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(global.apiUrl + 'Mailer/sendEnquiry/', formData, config)
          .then(res => {
            if (res.data == 'sent') {
              document.getElementById("fullname").value = '';
              document.getElementById("phone").value = '';
              document.getElementById("email").value = '';
              document.getElementById("content").value = '';
              document.getElementById("type").value = '0';
              document.getElementById("project_type").value = '0';
              this.state.fullname.value = '';
              this.state.phone.value = '';
              this.state.email.value = '';
              this.state.content.value = '';
              this.state.type.value = '0';
              this.state.project_type.value = '0';
              // this.setState({ alert: true });
              this.setState({ icon: 'success' });
              this.setState({ message: 'Submission Successful!' });
              //fbq('track', 'Purchase');
              ReactPixel.init('188385505216645', options);
              ReactPixel.pageView(); // For tracking page view
              if (typeofpage === 'commercial') {
                ReactPixel.track('Commercial Enquire Now', 'Commercial Enquire Now');
              }
              else if (typeofpage === '') {
                ReactPixel.track('Residential Enquire Now', 'Residential Enquire Now');
              }
              else {
                ReactPixel.track('Enquire Us Page Mail Action', 'Enquire Us Page Mail Action');
              }
              // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
              //ReactPixel.trackSingle('188385505216645', '', data); // For tracking default events.
              //ReactPixel.trackCustom('track', 'purchase'); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
              //ReactPixel.trackSingleCustom('188385505216645', event, data); // For tracking custom events.
            }
          })
      }


    }


    if (this.props.submitText === 'Submit') {

      if (this.state.fullname_download.value == '') {
        document.getElementById('fullname_download').classList.add('invalid__value');
        customValidDownload = false;
      }
      else {
        document.getElementById('fullname_download').classList.remove('invalid__value');
        customValidDownload = true;
      }
      if (this.state.phone_download.value == '') {
        document.getElementById('phone_download').classList.add('invalid__value');
        customValidDownload = false;
      }
      else {
        if (this.state.phone_download.value != '') {
          var numbers = /^[0-9-+()]*$/;
          if (this.state.phone_download.value.match(numbers)) {
            customValidDownload = true;
            document.getElementById('phone_download').classList.remove('invalid__value');
          }
          else {
            customValidDownload = false;
            document.getElementById('phone_download').classList.add('invalid__value');
          }
        }
      }

      if (this.state.email_download.value != '') {
        if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email_download.value)) {
          customValidDownload = true;
          document.getElementById('email_download').classList.remove('invalid__value');
        }
        else {
          customValidDownload = false;
          document.getElementById('email_download').classList.add('invalid__value');
        }
      }
      else if(this.state.email_download.value==='')
      {

        customValidDownload = false;
          document.getElementById('email_download').classList.add('invalid__value');
      }


    }



    //blog validation start

    if (this.props.submitText === 'Submit Now') {

      if (this.state.blogname.value == '') {
        document.getElementById('blogname').classList.add('invalid__value');
        customValidBlog = false;
      }
      else {
        document.getElementById('blogname').classList.remove('invalid__value');
        customValidBlog = true;
      }

      if (this.state.blogemail.value === '') {
        customValidBlog = false;
        document.getElementById('blogemail').classList.add('invalid__value');
      }
      else if (this.state.blogemail.value != '') {
        if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.blogemail.value)) {
          customValidBlog = true;
          document.getElementById('blogemail').classList.remove('invalid__value');
        }
        else {
          customValidBlog = false;
          document.getElementById('blogemail').classList.add('invalid__value');
        }
      }

      if (this.state.blogcomment.value === '') {
        customValidBlog = false;
        document.getElementById('blogcomment').classList.add('invalid__value');
      }
      else {
        customValidBlog = true;
        document.getElementById('blogcomment').classList.remove('invalid__value');
      }




    }

    //blog validation end



    if (this.props.submitText === 'Submit' && customValidDownload == true) {
      var contactnameDownloadValid = this.state.fullname_download.valid;
      var contactphoneDownloadValid = this.state.phone_download.valid;

      if (contactnameDownloadValid && contactphoneDownloadValid) {
        let formData = new FormData();
        var url = window.location.href;
        const typeofpage = url.substring(url.lastIndexOf('/') + 1)
        formData.append('contact_form_name', this.state.fullname_download.value);
        formData.append('contact_form_phone', this.state.phone_download.value);
        formData.append('contact_form_email', this.state.email_download.value);
        customValidDownload = false;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(global.apiUrl + 'Mailer/downloadBrochure/', formData, config)
          .then(res => {
            if (res.data) {
              ReactPixel.init('188385505216645', options);
              if (typeofpage === '')
                ReactPixel.track('Residential Download Brochure Action', 'Residential Download Brochure Action'); // For tracking default events.
              else
                ReactPixel.track('Commercial Download Brochure Action', 'Commercial Download Brochure Action'); // For tracking default events.
              console.log(typeofpage);

              document.getElementById("fullname_download").value = '';
              document.getElementById("phone_download").value = '';
              document.getElementById("email_download").value = '';
              this.state.fullname_download.value = '';
              this.state.phone_download.value = '';
              this.state.email_download.value = '';
              this.setState({ alert: true });

              setTimeout(function () {
                if ((document.getElementsByClassName('close')[0] !== undefined))
                  document.getElementsByClassName('close')[0].click()
              }, 3000);

            }
          })
      }


    }


    //blog start

    if (this.props.submitText === 'Submit Now' && customValidBlog == true) {
      var blog_comment_id = document.getElementById('blog_comment_id').value;

      var blognameValid = this.state.blogname.valid;
      var blogemailValid = this.state.blogemail.valid;
      var blogcommentValid = this.state.blogcomment.valid;
      if (blognameValid && blogemailValid && blogcommentValid) {

        let formData = new FormData();
        var url = window.location.href;
        const blog_id = url.substring(url.lastIndexOf('/') + 1)
        formData.append('blogname', this.state.blogname.value);
        formData.append('blogemail', this.state.blogemail.value);
        formData.append('blogcomment', this.state.blogcomment.value);
        formData.append('blog_id', blog_id);
        if (blog_comment_id !== 0) {
          formData.append('blog_comment_id', blog_comment_id);
        }

        customValidBlog = false;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(global.apiUrl + 'Blogs/postBlogWb/', formData, config)
          .then(res => {
            if (res.data) {
              document.getElementById("blogname").value = '';
              document.getElementById("blogemail").value = '';
              document.getElementById("blogcomment").value = '';
              document.getElementById("blog_comment_id").value = '0';
              this.state.blogname.value = '';
              this.state.blogemail.value = '';
              this.state.blogcomment.value = '';
              this.setState({ alert: true });
              setTimeout(function () {
                if ((document.getElementsByClassName('close')[0] !== undefined))
                  document.getElementsByClassName('close')[0].click()
              }, 3000);

            }
          })
      }


    }

    //blog end


  }

  render() {
    var textClass = '';
    var gridClass = '';
    var gridClassBtn = '';
    var blogClass = '';
    var blogdiv = this.props.submitText;
    { this.props.submitText === 'Enquire Now' ? textClass = 'text-right' : this.props.submitText === 'Submit' ? textClass = 'text-right' : textClass = 'text-center' }
    { this.props.submitText === 'Enquire Now' ? gridClass = 'col-md-12 col-12' : this.props.submitText === 'Submit' ? gridClass = 'col-md-12 col-12' : this.props.submitText === 'Submit Now' ? gridClass = 'col-md-6 col-6' : gridClass = 'col-md-4 col-lg-3 col-5' }
    { this.props.submitText === 'Enquire Now' ? gridClassBtn = 'col-md-12' : this.props.submitText === 'Submit' ? gridClassBtn = 'col-md-12' : gridClassBtn = 'col-lg-2 col-md-3' }

    return (
      <form noValidate id="enquiry-form" className="" autoComplete="off">
        <fieldset className="row pb-5 pt-3">
          {this.props.fields.map(n =>

            <div key={n.id} className={((blogdiv === 'Submit Now') && (n.id === 'blogcomment')) ? 'col-md-12 col-12' : ((blogdiv === 'Submit Now') && (n.id !== 'blogcomment')) ? ' col-md-6 col-6' : 'form-row ' + gridClass + '  mb-4 mb-sm-0 border-0'}>

              {(this.props.submitText === 'Enquire Now' || this.props.submitText === 'Submit') ?
                <label htmlFor={n.id}>
                  {n.label}
                  {
                    (n.label === 'Name' || n.label === 'Phone')
                      ?
                      (<font className="error">*</font>) : ''
                  }
                </label>
                : ''}
              <ControlledInput
                options={n}
                Banner_id={this.props.Banner_id}
                input={this.state[n.id].value}
                valid={this.state[n.id].valid}
                onBlur={this._onBlur}
                onClick={this._resetValidation}
                onChangeComplete={this._onChangeComplete}
              />
            </div>)
          }
          {(this.props.submitText === 'Enquire Now' || this.props.submitText === 'Submit') ?
            <div className={"form-row " + gridClassBtn + " col-12"}>
              <div className={textClass + ' submit-row '}>
                <input type='submit' value={this.props.submitText} onClick={this._submit} className='form-submit mt-3' />
              </div>
            </div>
            : <div className={"form-row " + gridClassBtn + " col-2"}>

              <input type='submit' value={this.props.submitText} onClick={this._submit} className='form-submit h-50 p-sm-0' />

            </div>}

        </fieldset>
        {this.state.alert && <SweetAlert
          type={this.state.icon}
          title={this.state.message}
          onConfirm={() => this.hideAlert()}
        >

        </SweetAlert>
        }
      </form>
    );
  }
}