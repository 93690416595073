const routes = [
   
    {
      label: 'RESIDENTIAL',
      path: '/',
      id:"residential",
      activeClassName:"navbar__link--active"
    },
    {
      label: 'COMMERCIAL',
      path: '/commercial',
      id:"commercial",
      activeClassName:"navbar__link--active"
    },
    {
      label: 'CALL US',
      path: '/call-us',
      id:"call-us"
    },
    {
      label: 'ENQUIRE US',
      path: '/contact-us',
      id:"contact-us"
    }
  ];
  
  export default routes;
  
