import  React, { Component } from 'react';
import { BrowserRouter as Router,useLocation,withRouter } from 'react-router-dom';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactForm from './form/form';
import './form/form.css';
import './input/input.css';
import './success/success.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import parse from 'html-react-parser';


class Blogbanners extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor()
      {
        super();
        this.state = 
        {
            blogbannersDetails:'',
        }
      }

      componentDidMount() 
      {
            var blog_id=window.location.href.split("/");
            
            axios.get(global.apiUrl+'Blogs/getblogbyId?blog_id='+blog_id[4])
            .then(res => {  
            const blogbannersDetails=res.data.blog_details;
            this.setState({ blogbannersDetails });     
            });
      }
      render (){
                            const { match, location, history } = this.props
                            const { pathname } = location;
                            const splitLocation = pathname.split("/");
                           
                            var para=splitLocation[1];
                            if(isNaN(para))
                            para=para;  
                            else
                            para='';
                           
                            var Background='';
                            var bannerClass='';
                            var bannertitle='';
                            var bannertext='';
                            var paddingClass='';
                            var project_banner_id=this.props.match.params.id;
                            
                
                        
                              
                  return (
                   
                 <>
                
             {/* {console.log(this.state.blogbannersDetails.length)} */}
                 {(this.state.blogbannersDetails.length===1) ? this.state.blogbannersDetails.map(bannersDet => {
             
         
                        var blog_ids=window.location.href.split("/");
                             //if(blog_ids[4]==='blogdetails')
                           // {
                            //   Background=global.imageUploadUrl+bannersDet.blog_image;
                              bannerClass='banner mt-lg-5';
                             // bannertitle=bannersDet.banner_title;
                              //bannertext=bannersDet.banner_tagline;
                              paddingClass='bbb';
                            //}
                            
                            // else
                            // {
                            //   paddingClass='';Background=global.imageUrl+'404.png';bannerClass='banner-404';bannertitle='404 Error';bannertext='Look like the page you are searching\n for is NOT BUILT YET!';
                            // }
                            // {console.log(global.imageUploadUrl+bannersDet.blog_image)}
                            // {console.log(global.imageUploadUrl+bannersDet.blog_image)}    
                            {console.log(global.imageUploadUrl+bannersDet.blog_image)}
                         return (
                           
                        
                          <div key={bannersDet.blog_image} className={bannerClass+' 111 container-fluid1 pt-5'} style={{cursor:"inherit",backgroundImage:"url("+global.imageUploadUrl+bannersDet.blog_image+")"}}>  
                                  
                                  <h1 className={" "+paddingClass+"  "}></h1>    

                                               
                                              
                          </div> 

                                 
                       
                         )
                        })
                    :""}
                        </>
 
                  )         
                 
}
}
export default withRouter(Blogbanners);