import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
// import PropTypes from 'prop-types';
import Analytics from './components/Analytics';
import Navigation from './components/Navigation';
import Banners from './components/Banners';
import Blogbanners from './components/Blogbanners';
// import Slider from './components/Slider';
import Breadcrumbs from './components/Breadcrumbs';
import {
  BrowserView,
  MobileOnlyView, MobileView, TabletView
} from "react-device-detect";


class Main extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    // alert(this.props.title)

    var href = window.location.href.split('/');
    return (
      <div>
        <Helmet >
          <Analytics />
          {this.props.title && <title>SD Developers | {this.props.title}</title>}
          <meta name="keywords" content={this.props.description} />
          <meta name="description" content={this.props.description} />
        </Helmet>
        <div id="wrapper">

          {this.props.title === "Policy" ? <Navigation /> : this.props.title === "Privacy" ? <Navigation /> : this.props.title === "Disclaimer" ? <Navigation /> : this.props.title === "Terms of Use" ? <Navigation /> : this.props.title === "News" ? <Navigation /> : this.props.title === "Residential" ? <Navigation /> :
            this.props.title === "AboutUs" ? <Navigation /> : this.props.title === "Enquire Us" ? <Navigation /> : this.props.title === "Commercial" ? <Navigation /> : this.props.title === "Blogs" ? <Navigation /> : this.props.title === "Blog Details" ? <Navigation /> : ''}

          {href[3] === "blogs" ? <Navigation /> : ''}

          <MobileOnlyView>
            <Breadcrumbs />
          </MobileOnlyView>


          {this.props.title === "404 Page1" ? <Banners /> : this.props.title === "Policy" ? <Banners /> : this.props.title === "Privacy" ? <Banners /> : this.props.title === "Disclaimer" ? <Banners /> : this.props.title === "Terms of Use" ? <Banners /> : this.props.title === "News" ? <Banners /> : this.props.title === "Residential" ? <Banners /> :
            this.props.title === "AboutUs" ? <Banners /> : this.props.title === "Enquire Us" ? <Banners /> : this.props.title === "Commercial" ? <Banners /> : this.props.title === "Blogs" ? <Banners /> : this.props.title === "Blog Details" ? <Blogbanners /> : ''}

          {/* {href[3]==="blogs"?<Blogbanners/>:''} */}
          <div id="main">
            {this.props.children}
          </div>
          {this.props.fullPage ? null : <div></div>}
        </div>
      </div>
    );
  }
}



// Main.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//   ]),
//   fullPage: PropTypes.bool,
//   title: PropTypes.string,
//   description: PropTypes.string,
// };

// Main.defaultProps = {
//   children: null,
//   fullPage: false,
//   title: null,
//   description: "SD Developers Web",
// };

export default Main;
