import { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import routes from './routes';
import { Navbar, Nav } from 'react-bootstrap';
import Canvasmenu from './Canvasmenu'
import { BrowserView, isTablet } from "react-device-detect";
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

function fbPixelcallHead() {
  // fbq('trackCustom', 'Call Header');
  ReactPixel.init('188385505216645', options);
  ReactPixel.track('Call header', 'Call header'); // For tracking default events.
  // More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
}
function fbPixelcallMenu() {
  ReactPixel.init('188385505216645', options);
  ReactPixel.track('Call Menu Action', 'Call Menu Action'); // For tracking default events.
}



export class Navigation extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor() {
    super();
    this.state =
    {
      contactDetails: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(global.apiUrl + '/Contact/getContactdetails')
      .then(res => {
        const contactDetails = res.data.contact;
        this.setState({ contactDetails });
      });
  }

  render() {
    const { location } = this.props
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (

      <section>
        {(this.state.contactDetails.length) ? this.state.contactDetails.map(contactDet => {
          if (contactDet != null) {
            return (
              <header id="header" key="header">
                <div className="conatiner">
                  <div className="row strip">
                    <div className="col-md-6 pl-0">
                      <div className="phone-one">
                        <img alt="ph" id="phone-icon" src={global.imageUrl + "phone.png"} />

                        <a className="stripphone" onClick={fbPixelcallHead} href={"tel:" + contactDet.company_phone}>{contactDet.company_phone}</a>

                      </div>
                    </div>
                    <div className="col-md-6 pr-0">
                      <div className="phone-two">
                        <img alt="ph" id="phone-icon" src={global.imageUrl + "phone.png"} />
                        <a onClick={fbPixelcallHead} className="stripphone sec-num" href={"tel:" + contactDet.company_mobile}>{contactDet.company_mobile}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="index-link row">

                  {/* image-logo-mobile col-md-4 col-4 col-sm-4 */}
                  <Link className="image-logo-mobile col-xl-4 col-md-3 col-lg-3 col-sm-4" to="/"><img alt="logo" src={global.imageUploadUrl + contactDet.company_logo} /></Link>
                  <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className="d-sm-block col-md-8 col-12 col-sm-8">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="float-sm-right" />
                    <Navbar.Collapse id="basic-navbar-nav " className="mt-lg-4 mt-sm-5 ml-sm-2 ml-lg-5 w-100">
                      <Nav className="mr-auto w-100 ">

                        {routes.filter((l) => !l.index).map((l) => (
                          l.id !== 'call-us' ?
                            <Link to={l.path} key={l.label} id={l.id} className={splitLocation[1] === l.path.split("/")[1] ? "active  mr-lg-3 mr-sm-2 ml-sm-2" : "  mr-lg-3 mr-sm-2 ml-sm-2"}>{l.label}</Link>
                            : <a key="call-us" id={l.id} href={"tel:" + contactDet.company_phone} className=" mr-lg-3 ml-lg-3 mr-sm-2 ml-sm-2"> CALL US </a>

                        ))}

                        <Link to={'about-us'} key={'AboutUs'} id={'AboutUs'} className={splitLocation[1] === 'about-us' ? "active d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3" : " d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3"}>{'ABOUT US'}</Link>
                        <Link to={'news'} key={'news'} id={'news'} className={splitLocation[1] === 'news' ? "active d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3" : " d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3"}>{'NEWS'}</Link>
                        <Link to={'policy'} key={'policy'} id={'policy'} className={splitLocation[1] === 'policy' ? "active d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3" : " d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3"}>{'POLICIES'}</Link>
                        {/* <Link to={'NotFound'} key={'NotFound'} id={'NotFound'} className={splitLocation[1] === 'NotFound' ? "active d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3" : " d-lg-none mr-sm-2 ml-sm-2 mr-lg-3 ml-lg-3"}>{'404'}</Link> */}
                      </Nav>

                      {!isTablet && <BrowserView>
                        <Canvasmenu />
                      </BrowserView>}
                    </Navbar.Collapse>
                  </Navbar>

                </div>
              </header>
            )
          }
          return console.log('')
        })

          : ''}
      </section>

    )
  }
};

export default withRouter(Navigation);
