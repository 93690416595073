import React, { Component } from 'react';
import { BrowserRouter as Router, useLocation, withRouter } from 'react-router-dom';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactForm from './form/form';
import SuccessMsg from './success/success';
import './form/form.css';
import './input/input.css';
import './success/success.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import parse from 'html-react-parser';
const bannerFields = [
  {
    id: 'city',
    placeholder: 'City',
    type: 'select',
    options: {
      required: false
    }
  },
  {
    id: 'project',
    placeholder: 'Project',
    type: 'select',
    options: {
      required: true
    }
  }
];

class Banners extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor() {
    super();
    this.state =
    {
      bannersDetails: '',
    }
  }

  componentDidMount() {
    axios.get(global.apiUrl + 'Pages/getallBanners')
      .then(res => {
        const bannersDetails = res.data.banners;
        this.setState({ bannersDetails });
      });
  }
  render() {
    const { match, location, history } = this.props
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    var para = splitLocation[1];
    if (isNaN(para))
      para = para;
    else
      para = 'residential';

    var Background = '';
    var bannerClass = '';
    var bannertitle = '';
    var bannertext = '';
    var paddingClass = '';
    var project_banner_id = this.props.match.params.id;



    if (this.state.bannersDetails) {
      this.state.bannersDetails.map((bannersDet, i) => {

        if (project_banner_id === undefined && bannersDet.banner_page === 'residential' && para === 'residential') {
          project_banner_id = bannersDet.banner_project;
        }
        if (project_banner_id === undefined && bannersDet.banner_page == 'commercial' && para === 'commercial') {
          project_banner_id = bannersDet.banner_project;
        }
      })
    }


    return (
      <>

        {(this.state.bannersDetails.length > 0) ? this.state.bannersDetails.map(bannersDet => {
          // console.log(bannersDet.banner_page)

          if (para === 'NotFound' && bannersDet.banner_page === 'NotFound') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner-404';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'policy' && bannersDet.banner_page === 'policy') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner policy-banner mt-lg-0';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }

          else if (para === 'disclaimer' && bannersDet.banner_page === 'disclaimer') {
            Background = global.bannerUploadUrl + bannersDet.banner_image; bannerClass = 'banner policy-banner mt-lg-0'; bannertitle = 'Disclaimer'; bannertext = '';
            paddingClass = '';
          }
          else if (para === 'terms-of-use' && bannersDet.banner_page === 'terms-of-use') { Background = global.bannerUploadUrl + bannersDet.banner_image; bannerClass = 'banner policy-banner mt-lg-0'; bannertitle = 'Terms of Use'; bannertext = ''; }

          else if (para === 'privacy-policy' && bannersDet.banner_page === 'privacy-policy') { paddingClass = ''; Background = global.bannerUploadUrl + bannersDet.banner_image; bannerClass = 'banner policy-banner mt-lg-0'; bannertitle = 'Privacy Policy'; bannertext = ''; }

          else if (para === 'news' && bannersDet.banner_page === 'news') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner mt-lg-5';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'residential' && bannersDet.banner_page === 'residential') {

            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner-projects mt-lg-5';
            bannertitle = parse(bannersDet.banner_title);
            if (bannertitle.length < 1) {
              bannertitle = parse("<br/><br/><br/><br/>");
            } else if (bannertitle.length < 10) {
              bannertitle = parse(bannersDet.banner_title + "<br/><br/>");
            }

            bannertext = parse(bannersDet.banner_tagline);
            console.log(bannertext)
            /* if (bannertext.length < 1) {
              bannertext = parse("<br/><br/><br/>");
            } else if (bannertext.length < 10) {
              bannertext = parse(bannersDet.banner_title + "<br/>");
            } */
            paddingClass = 'mt-lg-5 pt-5 pt-sm-10';
          }
          else if (para === 'about-us' && bannersDet.banner_page === 'about-us') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner-about mt-lg-5';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'blogs' && bannersDet.banner_page === 'blogs') {

            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner mt-lg-5';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'blog' && bannersDet.banner_page === 'blog') {

            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner mt-lg-5';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'contact-us' && bannersDet.banner_page === 'contact-us') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner mt-lg-5';
            bannertitle = bannersDet.banner_title;
            bannertext = bannersDet.banner_tagline;
            paddingClass = '';
          }
          else if (para === 'commercial' && bannersDet.banner_page === 'commercial') {
            Background = global.bannerUploadUrl + bannersDet.banner_image;
            bannerClass = 'banner-projects mt-lg-5';
            bannertitle = parse(bannersDet.banner_title);
            if (bannertitle.length < 1) {
              bannertitle = parse("<br/><br/><br/><br/>");
            } else if (bannertitle.length < 10) {
              bannertitle = parse(bannersDet.banner_title + "<br/><br/>");
            }

            bannertext = parse(bannersDet.banner_tagline);
            if (bannertext.length < 1) {
              bannertext = parse("<br/><br/><br/>");
            } else if (bannertext.length < 10) {
              bannertext = parse(bannersDet.banner_title + "<br/>");
            }
            paddingClass = 'mt-lg-5 pt-5 pt-sm-10';
          }
          else {
            paddingClass = '';
            Background = global.imageUrl + '404.png';
            bannerClass = 'banner-404';
            bannertitle = '404 Error';
            bannertext = 'Look like the page you are searching\n for is NOT BUILT YET!';
          }

          //console.log(para);
          //console.log(bannersDet.banner_page);
          //console.log(project_banner_id);
          //console.log(bannersDet.banner_project);
          //console.log("========");
          return (
            ((para === bannersDet.banner_page) && (project_banner_id === bannersDet.banner_project)) ?
              //project pages
              <div key={bannersDet.banner_page} className={bannerClass + ' container-fluid1 pt-5'} style={{ backgroundImage: "url(" + Background + ")" }}>

                <h1 className={" " + paddingClass + "  "}>{bannertitle}</h1>
                {(para === 'NotFound' || para === 'residential' || para === 'commercial') ?
                  <div align="center" className="container-fluid">
                    <label>{bannertext}</label>
                    {para === 'NotFound' ?
                      <Button variant="primary" size="lg" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = './';
                      }}>
                        Back to Home
                      </Button>
                      : ""}
                    {/* BANNER FORM START */}
                    {(para === 'residential' || para === 'commercial') ?
                      <div className="bannerform pb-5 row">
                        <div className="col-lg-3 col-md-2"></div>
                        <div className="col-lg-9 col-md-10 pl-4">
                          <ContactForm Banner_id={project_banner_id} fields={bannerFields} submitText='Search' />
                        </div>                      </div>
                      : ""}
                    {/* BANNER FORM END */}
                  </div>
                  : ""}
              </div>
              : ((para === bannersDet.banner_page) && (para != 'residential' && para != 'commercial')) ?
                //other pages 
                <div key={bannersDet.banner_page} className={bannerClass + ' container-fluid1 pt-5'} style={{ backgroundImage: "url(" + Background + ")" }}>

                  <h1 className={" " + paddingClass + "  "}>{bannertitle}</h1>

                  {(para === 'NotFound' || para === 'residential' || para === 'commercial') ?
                    <div align="center" className="container-fluid">
                      <label>{bannertext}</label>
                      {para === 'NotFound' ?
                        <Button variant="primary" size="lg" onClick={(e) => {
                          e.preventDefault();
                          window.location.href = './';
                        }}>
                          Back to Home
                        </Button>
                        : ""}
                    </div>
                    : ""}

                </div>

                : ''



          )

        })
          : ''}
      </>

    )
  }
}
export default withRouter(Banners);