
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useLocation } from 'react-router-dom';
export default function Breadcrumbs() {
  const search = useLocation().pathname.split("/");
  var para=search[1]; 


  return (
    <div className="breadcrumb-div">
 
      <Breadcrumb>
          <Breadcrumb.Item href="/">
              Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
              {para!=='' ? para :'Residential'}
          </Breadcrumb.Item>
      </Breadcrumb>
   
    </div>
  );
}